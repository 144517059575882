body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
  color: #000000;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF !important;
}

/* Override Leaflet controls to match theme */
.leaflet-control-container .leaflet-control {
  background-color: #FFFFFF !important;
  border: 1px solid #000000 !important;
  color: #000000 !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  color: #000000 !important;
  background-color: #FFFFFF !important;
}

.leaflet-control-attribution {
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #000000 !important;
}

.custom-marker-cluster {
  background: #000;
  border-radius: 50%;
  color: white;
  width: 40px !important;
  height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 2px solid white;
}

.cluster-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
} 